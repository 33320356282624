import React, { useEffect, useState } from "react";
import BaseModal from "../../modals/BaseModal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./gallery.css";

import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import useEscapeKey from "../../hooks/useEscapeKey";

const ImageModal = ({ selectedImage, images, onClose, visible }) => {
  useEscapeKey(onClose);
  // const ref = useRef(null);
  // useOutsideClick(onClose, ref);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [initialSlide, setInitialSlide] = useState(0);

  useEffect(() => {
    const selectedIndex = images?.findIndex((el) => el.id === selectedImage);
    if (selectedIndex !== -1) {
      setInitialSlide(selectedIndex);
    }
  }, [images, selectedImage]);

  return (
    <BaseModal visible={visible} onClose={onClose}>
      {images ? (
        <>
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
            initialSlide={initialSlide}
          >
            {images?.map((el) => (
              <SwiperSlide key={el.id}>
                <img src={el.img} alt={`img-${el.id}`} data-img-fit="contain" />
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
            initialSlide={initialSlide}
          >
            {images?.map((el) => (
              <SwiperSlide key={el.id}>
                <img src={el.img} alt={`img-${el.id}`} data-img-fit="cover" />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : null}
    </BaseModal>
  );
};

export default ImageModal;
