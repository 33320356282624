import React from "react";
import AssetsImg from "../../assets/AssetsImg";
import "./loader.css";

const Loader = () => {
  return (
    <div className="loader-container center-content">
      <div className="fade-in center-content">
        <img src={AssetsImg.BrandLogo} alt="sign" />
      </div>
      <h5 dir="ltr">Loading ...</h5>
    </div>
  );
};

export default Loader;
