import React from "react";
import { useTranslation } from "react-i18next";
import { LuPartyPopper } from "react-icons/lu";
import { FaCamera } from "react-icons/fa";

const CTA = () => {
  const { t } = useTranslation();
  return (
    <div className="cta">
      <a href="#ourPackages" className="btn btn-primary">
        {t("cta1")}
        <LuPartyPopper className="btn-icon" />
      </a>
      <a href="#gallery" className="btn">
        {t("cta2")}
        <FaCamera className="btn-icon" />
      </a>
    </div>
  );
};

export default CTA;
