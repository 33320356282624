import React from "react";
import "./baseModal.css";
import { IoClose } from "react-icons/io5";
const BaseModal = (props) => {
  const { visible, backClose, onClose, children, withoutBg } = props;
  return visible ? (
    <div
      className={visible ? "modal" : "modal  modal-visibility"}
      style={{ display: visible ? "fixed!important" : "none" }}
      onClick={backClose ? onClose : null}
    >
      <div
        className="modal-content"
        onClick={() => null}
        data-modal-content={withoutBg ? "withoutBg" : "withBg"}
      >
        <IoClose className="modal-close" onClick={onClose} />
        {children}
      </div>
    </div>
  ) : null;
};

export default BaseModal;
