import React from "react";
import { useTranslation } from "react-i18next";
import AssetsImg from "../../assets/AssetsImg";

const Caption = () => {
  const { t } = useTranslation();
  return (
    <div className="container caption-container">
      <div className="success-parties">
        <h2>{"100+"}</h2>
        <h6>{t("success-parties")}</h6>
      </div>
      <div className="success-parties">
        <h2>{"70+"}</h2>
        <h6>{t("success-sections")}</h6>
      </div>
      <div className="quote">
        <h5>{t("caption")}</h5>
      </div>

      <div className="success-img">
        <img alt="celebrate Icon 1" src={AssetsImg.CaptionImg} />
      </div>
    </div>
  );
};

export default Caption;
