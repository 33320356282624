import React from "react";
import { useTranslation } from "react-i18next";
import Section from "../../components/section/section";
import { BsInstagram } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./instagram.css";
import AssetsImg from "../../assets/AssetsImg";
const Instagram = () => {
  const { t } = useTranslation();
  return (
    <Section fadeEnabled={false} id={"instagram"} title={t("instagram_follow")}>
      <div className="wrapper">
        <div className="flex-column-center">
          <Link to="https://www.instagram.com/funtastic.place/" target="_blank">
            <BsInstagram />
            <span dir="ltr">{"@funtastic.place"}</span>
            <img
              src={AssetsImg.InstagramPage}
              alt="Instagram"
              className="instagram_phone"
            />
          </Link>
        </div>
        <img
          src={AssetsImg.InstagramGrid}
          alt="Instagram Posts"
          className="instagram_grid"
        />
      </div>
    </Section>
  );
};

export default Instagram;
