import React from "react";
import "./contact.css";
import { MdOutlineEmail, MdLocationPin } from "react-icons/md";
import { BsClock, BsWhatsapp } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import Section from "../../components/section/section";
import AssetsImg from "../../assets/AssetsImg";
const Contact = () => {
  const { t } = useTranslation();
  // React.useEffect(() => {
  //   function initMap() {
  //     const map = new window.google.maps.Map(document.getElementById("map"), {
  //       zoom: 4,
  //       disableDefaultUI: true,
  //       zoomControl: false,
  //       scaleControl: false,
  //     });
  //   }
  //   window.initMap = initMap;
  // }, []);

  return (
    <Section id="contact" title={t("GetInTouch")} text={t("packages_details")}>
      <div className="container contact__container">
        <div style={{ width: "100%" }}>
          <div className="contact__options">
            <a
              href="https://api.whatsapp.com/send?phone=972543311698&text=%D8%A7%D9%86%D8%A7%20%D9%85%D9%87%D8%AA%D9%85%20%D8%A8%D8%A7%D9%84%D8%AA%D9%88%D8%A7%D8%B5%D9%84%20%D9%85%D8%B9%D9%83%D9%85"
              rel="noreferrer"
              target="_blank"
              className="contact__option"
            >
              <article>
                <BsWhatsapp className="contact__option-icon" />
                <h5>{t("Whatsapp")}</h5>
                <h6 dir="ltr">{"(+972) 0543311698"}</h6>
                <span className="link">{t("SendAMessage")}</span>
              </article>
            </a>
            <a
              href="mailto:khadijaessa.bder@gmail.com"
              rel="noreferrer"
              target="_blank"
              className="contact__option"
            >
              <article>
                <MdOutlineEmail className="contact__option-icon" />
                <h5>{t("Email")}</h5>
                <h6>khadijaessa.bder@gmail.com</h6>
                <span className="link">{t("SendAMessage")}</span>
              </article>
            </a>
          </div>
          <div className="contact__options">
            <div className="contact__option map-container">
              <iframe
                title="location"
                id="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3379.360975825484!2d34.963425673136186!3d32.1135522803211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d3193f79c6a69%3A0x845652f58ec5d982!2sMahmoud%20El%20Nemer%20st%206%2C%20Kfar%20Qasem!5e0!3m2!1sen!2sil!4v1690566653301!5m2!1sen!2sil"
                width={"100%"}
                height={"100%"}
                // width={mapDimension.width}
                // height={mapDimension.height}
                loading="lazy"
                language="he"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="contact__option store_info">
              <div
                className="flex-row-start"
                style={{ justifyContent: "flex-start" }}
              >
                <MdLocationPin />
                <p>Mahmoud El Nemer st 6, Kfar Qasem </p>
              </div>
              <div
                className="flex-row-start"
                style={{ justifyContent: "flex-start" }}
              >
                <BsClock />
                <p>
                  {t("work_time")}
                  <br />
                  <small>{t("work_time_desc")}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="image_fly_container">
          <img src={AssetsImg.Girl} alt={"Girl"} />
          <div className="balloon fly">
            <img src={AssetsImg.Pattern} alt={"Pattern"} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Contact;
