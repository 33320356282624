import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { FaChevronUp } from "react-icons/fa";
import Footer from "../partials/footer/Footer";
import TranslateButton from "../components/TranslateButton";
import Nav from "../partials/nav/Nav";
import { DeviceType, useDeviceType } from "../hooks/DeviceType";
import AssetsImg from "../assets/AssetsImg";
import useScrollToTop from "../hooks/scrollTo";
import LgNav from "../partials/nav/lgNav";

const Layout = (props) => {
  const deviceType = useDeviceType();
  const isMobile =
    deviceType === DeviceType.MOBILE || deviceType === DeviceType.XS_MOBILE;
  const [activeNav, setActiveNav] = useState("#");
  const { scrollToTop } = useScrollToTop();
  const main = document.getElementById("main")?.getBoundingClientRect().top;
  const aboutElement = document
    .getElementById("about")
    ?.getBoundingClientRect().top;
  const servicesElement = document
    .getElementById("services")
    ?.getBoundingClientRect().top;
  const packagesElement = document
    .getElementById("ourPackages")
    ?.getBoundingClientRect().top;
  const contactElement = document
    .getElementById("contact")
    ?.getBoundingClientRect().top;
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleScroll = () => {
    let yPosition = window.pageYOffset - 190;
    if (main <= yPosition) {
      setActiveNav("#main");
    }
    if (aboutElement <= yPosition) {
      setActiveNav("#about");
    }
    if (servicesElement <= yPosition) {
      setActiveNav("#services");
    }
    if (packagesElement <= yPosition) {
      setActiveNav("#ourPackages");
    }
    if (contactElement <= yPosition) {
      setActiveNav("#contact");
    }
  };
  const scrollTo = (to) => {
    scrollToTop(to.replace("#", ""));
    setActiveNav(to);
  };
  return (
    <>
      <LgNav activeNav={activeNav} />
      {props.isWithNav && isMobile ? (
        <Nav scrollTo={scrollTo} activeNav={activeNav} />
      ) : null}
      <Outlet />
      <Footer />
      <div
        className="scroll__settings"
        style={{
          display: props.visible ? "flex" : "none",
        }}
      >
        <div className="flex-row-center">
          <button className="scroll__top-btn" onClick={props.scrollToTop}>
            <FaChevronUp />
          </button>
          <TranslateButton
            translate={props.handleSiteLanguage}
            currentLanguage={props.currentLanguage}
          />
        </div>
        <Link
          to="https://api.whatsapp.com/send?phone=972543311698&text=%D8%A7%D9%86%D8%A7%20%D9%85%D9%87%D8%AA%D9%85%20%D8%A8%D8%A7%D9%84%D8%AA%D9%88%D8%A7%D8%B5%D9%84%20%D9%85%D8%B9%D9%83%D9%85"
          target="_blank"
          className="whatsapp-btn"
          onClick={props.scrollToTop}
        >
          <img src={AssetsImg.Whatsapp} alt="whatsapp icon" />
        </Link>
      </div>
    </>
  );
};

export default Layout;
