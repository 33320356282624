import React from "react";
// import { RiTranslate } from "react-icons/ri";

const TranslateButton = (props) => {
  const currentLanguage = () => {
    let langWord = "עברית";
    switch (props.currentLanguage) {
      case "he":
        langWord = "العربية";
        break;
      case "ar":
        langWord = "עברית";
        break;
      default:
        langWord = "עברית";
        break;
    }
    return langWord;
  };
  const Translate = () => {
    const translateTo = props.currentLanguage === "he" ? "ar" : "he";
    props.translate(translateTo);
  };
  return (
    <div onClick={Translate} className={"translate__button"}>
      {/* <RiTranslate /> */}
      <small>{currentLanguage()}</small>
    </div>
  );
};

export default TranslateButton;
