import React from "react";
import "./nav.css";
import { AiOutlineHome } from "react-icons/ai";
import { BiMessageSquareDetail } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { RxBackpack } from "react-icons/rx";
import { TiBusinessCard } from "react-icons/ti";
const Nav = ({ activeNav, scrollTo }) => {
  return (
    <div className="customNav">
      <button
        onClick={() => scrollTo("#main")}
        className={activeNav === "#main" ? "active" : ""}
      >
        <AiOutlineHome />
      </button>
      <button
        onClick={() => scrollTo("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <TiBusinessCard />
      </button>
    
      <button
        onClick={() => scrollTo("#ourPackages")}
        className={activeNav === "#ourPackages" ? "active" : ""}
      >
        <RxBackpack />
      </button>
      <button
        onClick={() => scrollTo("#services")}
        className={activeNav === "#services" ? "active" : ""}
      >
        <RiServiceLine />
      </button>
      <button
        onClick={() => scrollTo("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <BiMessageSquareDetail />
      </button>
    </div>
  );
};

export default Nav;
