import React, { useState, useRef, useEffect } from "react";
import "./section.css";
import SectionTitle from "./sectionTitle";

const Section = ({
  id,
  title,
  text,
  button,
  children,
  removePadding,
  fadeEnabled = true,
}) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();
  useEffect(() => {
    if (fadeEnabled) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }
  }, [fadeEnabled]);
  return (
    <section
      id={id}
      className={`${removePadding ? "" : "container"} fade-in-section ${
        isVisible ? "is-visible" : ""
      } ${!fadeEnabled ? "is-visible" : ""}`}
      ref={fadeEnabled ? domRef : null}
    >
      {title ? <h1>{title}</h1> : null}
      <SectionTitle text={text} button={button} />
      {children}
    </section>
  );
};

export default Section;
