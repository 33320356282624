import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./breadcrumbs.css";
import { TbBackslash } from "react-icons/tb";
const Breadcrumbs = ({ crumbs }) => {
  const { t } = useTranslation();
  if (crumbs.length <= 1) {
    return null;
  }
  return (
    <div className="container">
      <div className="breadcrumbs__container">
        {crumbs.map(({ name, path }, key) =>
          key + 1 === crumbs.length ? (
            <div key={`current-${key}`} className="flex-row-center current">
              <TbBackslash />
              <span>{t(name)}</span>
            </div>
          ) : (
            <Link key={`active-${key}`} to={path} className="active">
              {t(name)}
            </Link>
          )
        )}
      </div>
    </div>
  );
};
export default Breadcrumbs;
