import React from "react";
import AssetsImg from "../../assets/AssetsImg";
import { Container, Navbar, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./nav.css";
import { DeviceType, useDeviceType } from "../../hooks/DeviceType";
const LgNav = ({ activeNav }) => {
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const isMobile =
    deviceType === DeviceType.MOBILE || deviceType === DeviceType.XS_MOBILE;
  return (
    <Navbar fixed="top" className="customNav__lg">
      <Container>
        {isMobile ? null : (
          <Navbar id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                className={activeNav === "#main" ? "active-nav" : ""}
                href="#main"
              >
                {t("section1")}
              </Nav.Link>
              <Nav.Link
                className={activeNav === "#about" ? "active-nav" : ""}
                href="#about"
              >
                {t("section2")}
              </Nav.Link>
              <Nav.Link
                className={activeNav === "#ourPackages" ? "active-nav" : ""}
                href="#ourPackages"
              >
                {t("section4")}
              </Nav.Link>
              <Nav.Link
                className={activeNav === "#services" ? "active-nav" : ""}
                href="#services"
              >
                {t("section3")}
              </Nav.Link>
            </Nav>
          </Navbar>
        )}
        <Navbar.Brand href="/">
          <img alt="BrandLogo" src={AssetsImg.BrandLogo} width="100" />
        </Navbar.Brand>
        <Nav.Link href="#contact" className="justify-content-end">
          <div className="position-relative">
            <img
              alt="celebrate Icon 1"
              src={AssetsImg.celebrateIco2}
              className="button-icon celebrate-icon1"
            />
            <span className="btn btn-primary">{t("section5")}</span>
            <img
              alt="celebrate Icon 2"
              src={AssetsImg.celebrateIco1}
              className="button-icon celebrate-icon2"
            />
          </div>
        </Nav.Link>
      </Container>
    </Navbar>
  );
};

export default LgNav;
