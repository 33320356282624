import React from "react";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Section from "../../components/section/section";
import { useTranslation } from "react-i18next";
import Slider2 from "../../partials/slider2/slider2";
import AssetsImg from "../../assets/AssetsImg";
import "./About.css";
import { TbChartBubble, TbFileCertificate } from "react-icons/tb";
import { MdOutlineVolunteerActivism, MdOutlineColorLens } from "react-icons/md";
import { TiGroupOutline } from "react-icons/ti";
import { GrWorkshop } from "react-icons/gr";
const About = () => {
  const { t } = useTranslation();

  const crumbs = [
    { name: "section1", path: "/", key: 0 },
    { name: "section2", path: "About", key: 1 },
  ];
  const experiences = [
    {
      id: 0,
      title: t("experience1"),
      description: t("experience_description1"),
      icon: TbFileCertificate,
    },
    {
      id: 1,
      title: t("experience2"),
      description: t("experience_description2"),
      icon: GrWorkshop,
    },
    {
      id: 2,
      title: t("experience3"),
      description: t("experience_description3"),
      icon: MdOutlineVolunteerActivism,
    },
    {
      id: 3,
      title: t("experience4"),
      description: t("experience_description4"),
      icon: TbChartBubble,
    },
    {
      id: 4,
      title: t("experience5"),
      description: t("experience_description5"),
      icon: MdOutlineColorLens,
    },
    {
      id: 5,
      title: t("experience6"),
      description: t("experience_description6"),
      icon: TiGroupOutline,
    },
  ];
  return (
    <div className="index_wrapper page">
      <Breadcrumbs crumbs={crumbs} />
      <Section
        fadeEnabled={false}
        id={"about"}
        title={t("welcome_text")}
        text={t("about_us_sub")}
      >
        <div className="about_header">
          <div>
            <h2>{t("about_owner")}</h2>
            <p>{t("about_owner_p")}</p>
            <h2>
              {t("about_vision")}
              <span style={{ color: "#EF93A2" }}>{" FUNTASTIC "}</span>
            </h2>
            <p>{t("about_vision_p")}</p>
          </div>
          <img src={AssetsImg.AboutCover} alt="about cover" />
        </div>

        <div className="experience_cards">
          {experiences.map((experience) => (
            <div className="card flex-column-center" key={experience.id}>
              <div className="icon-card">{<experience.icon />}</div>
              <strong>{experience.title}</strong>
              <p>{experience.description}</p>
            </div>
          ))}
        </div>
        <h2>{t("sets_us_apart")}</h2>
        <p>{t("sets_us_apart_p")}</p>
        <Slider2 isPanoramic />
      </Section>
    </div>
  );
};

export default About;
