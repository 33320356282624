import React from "react";
import "./footer.css";
import { FaFacebookF } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import AssetsImg from "../../assets/AssetsImg";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="noprint">
      <div className="footer__line"/>
      <div className="footer__socials">
        <a
          href="https://api.whatsapp.com/send?phone=972543311698&text=%D8%A7%D9%86%D8%A7%20%D9%85%D9%87%D8%AA%D9%85%20%D8%A8%D8%A7%D9%84%D8%AA%D9%88%D8%A7%D8%B5%D9%84%20%D9%85%D8%B9%D9%83%D9%85"
          rel="noreferrer"
          target="_blank"
        >
          <BsWhatsapp />
        </a>
        <a
          href="https://www.instagram.com/funtastic.place/"
          rel="noreferrer"
          target="_blank"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com/funtastic.place/"
          rel="noreferrer"
          target="_blank"
        >
          <BsInstagram />
        </a>
      </div>
      <div className="footer__copyright">
        <Link
          dir="ltr"
          to="https://arkanjbali.herokuapp.com/"
          target="_blank"
          // className="footer__logo"
        >
          <small> {"Designed & Developed by <Arkan />"}</small>
        </Link>
        <small>&copy; {t("copyrights")}</small>
      </div>
      <img src={AssetsImg.AboutCover} alt="footer logo" className="footer__logo"/>
    </footer>
  );
};

export default Footer;
