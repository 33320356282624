import FuntasticIntro from "../assets/videos/funtastic-place-intro.mp4";
import FuntasticBD1 from "../assets/videos/funtastic-place-birthdate-video1.mp4";
import FuntasticBD2 from "../assets/videos/funtastic-place-birthdate-video2.mp4";
import FuntasticBD3 from "../assets/videos/funtastic-place-birthdate-video3.mp4";
import FuntasticBD4 from "../assets/videos/funtastic-place-birthdate-video4.mp4";
import FuntasticBD5 from "../assets/videos/funtastic-place-birthdate-video5.mp4";
import FuntasticBD6 from "../assets/videos/funtastic-place-birthdate-video6.mp4";
import FuntasticBD7 from "../assets/videos/funtastic-place-birthdate-video7.mp4";
import FuntasticBD8 from "../assets/videos/funtastic-place-birthdate-video8.mp4";
import FuntasticBD9 from "../assets/videos/funtastic-place-birthdate-video9.mp4";
import FuntasticBD10 from "../assets/videos/funtastic-place-birthdate-video10.mp4";
import FuntasticWorkshop1 from "../assets/videos/funtastic-place-workshop-video1.mp4";
import FuntasticWorkshop2 from "../assets/videos/funtastic-place-workshop-video2.mp4";
import FuntasticWorkshop3 from "../assets/videos/funtastic-place-workshop-video3.mp4";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  FuntasticIntro,
  FuntasticBD1,
  FuntasticBD2,
  FuntasticBD3,
  FuntasticBD4,
  FuntasticBD5,
  FuntasticBD6,
  FuntasticBD7,
  FuntasticBD8,
  FuntasticBD9,
  FuntasticBD10,
  FuntasticWorkshop1,
  FuntasticWorkshop2,
  FuntasticWorkshop3,
};
