import React from "react";
import { useTranslation } from "react-i18next";
import Section from "../../components/section/section";
import AssetsImg from "../../assets/AssetsImg";
import "./services.css";
const GeneralServices = () => {
  const { t } = useTranslation();
  const services = [
    {
      id: 1,
      title: t("service_title1"),
      details: t("service_birthday"),
      img: AssetsImg.ServiceBD,
      isReversed: false,
    },
    {
      id: 2,
      title: t("service_title5"),
      details: t("service_art"),
      img: AssetsImg.ServiceArt,
      isReversed: true,
    },
    {
      id: 3,
      title: t("service_title2"),
      details: t("service_workshop"),
      img: AssetsImg.ServiceWorkshop,
      isReversed: false,
    },
  ];
  return (
    <Section id={"GeneralServices"} title={t("general_services")}>
      <div className="general_services flex-column-start">
        {services.map((el) => (
          <div
            key={el.id}
            className={`general_service flex-row-start ${
              el.isReversed ? "reversed" : ""
            }`}
            style={{alignItems: 'flex-end'}}
          >
            <div className="service_img">
              <img src={el.img} alt={`serviceImg-${el.id}`} />
            </div>
            <div className="service_info">
              <h1>{`0${el.id}`}</h1>
              <h2>{el.title}</h2>
              <p>{el.details}</p>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default GeneralServices;
