import React from "react";
import { useTranslation } from "react-i18next";
import Section from "../../components/section/section";
// import AssetsImg from "../../assets/AssetsImg";
// import { GiPartyHat } from "react-icons/gi";
import { BsArrowLeft, BsCheckSquare } from "react-icons/bs";

import "./packages.css";
import useScrollToTop from "../../hooks/scrollTo";

const Packages = () => {
  const { t } = useTranslation();
  const { scrollToTop } = useScrollToTop();
  const packages = [
    // {
    //   id: 1,
    //   name: t("package1"),
    //   description: t("package_description1"),
    //   badge: "Delightful Celebration",
    //   isSpecial: false,
    // },
    {
      id: 4,
      name: t("package4"),
      description: t("package_description4"),
      footerDesc: t("package_footer_description4"),
      badge: "Exclusive Party",
      isSpecial: false,
    },
    // {
    //   id: 2,
    //   name: t("package2"),
    //   description: t("package_description2"),
    //   badge: "Exclusive Luxe",
    //   isSpecial: false,
    // },
    // {
    //   id: 3,
    //   name: t("package3"),
    //   description: t("package_description3"),
    //   badge: "Creative DIY Celebration",
    //   isSpecial: false,
    // },
  ];
  return (
    <Section
      id={"ourPackages"}
      title={t("section4")}
      text={t("packages_subtitle")}
    >
      <div className="packages_wrapper">
        <div id="pageSection" className="package" style={{ flex: 0.7 }}>
          <div className="pageSection flex-column-center header__container">
            <h2> {t("funtastic_package_title1")}</h2>
            <h2> {t("funtastic_package_title2")}</h2>
            <h1>
              <strong style={{ color: "#FF009A" }}>
                {t("funtastic_package_title3")}
              </strong>
            </h1>
            <h5>
              <strong style={{ background: "#fff" }}>
                {t("funtastic_package_p")}
              </strong>
            </h5>
          </div>
        </div>
        {packages.map((el, index) => (
          <div
            className={`package ${el.isSpecial ? "spacial_package" : ""}`}
            key={`package-${index}`}
          >
            <h4>{el.name}</h4>
            <span className="description">{el.description}</span>
            <div className="badge">
              <span>{el.badge}</span>
            </div>
            {Array.from({ length: 7 }, (_, i) => {
              return (
                <div className="option" key={`option-${i}`}>
                  <div className="flex-column-center icon">
                    <BsCheckSquare />
                  </div>
                  <span>{t(`package${el.id}_sub${i + 1}`)}</span>
                </div>
              );
            })}
            <span className="description">{el.footerDesc}</span>
          </div>
        ))}
      </div>
      <div className="flex-row-start packages_footer">
        <p>{t("packages_details_sub")}</p>
        <button onClick={() => scrollToTop("contact")} className="btn">
          {t("cta3")}
          <BsArrowLeft className="btn-icon" />
        </button>
      </div>
    </Section>
  );
};

export default Packages;
