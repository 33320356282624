import React, { useRef, useState } from "react";
import AssetsImg from "../../assets/AssetsImg";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaCirclePause } from "react-icons/fa6";
import "./Video.css";

const CustomVideo = ({
  src,
  poster,
  width,
  height,
  autoPlay,
  loop,
  controls,
  muted,
  id,
  showControl = true,
}) => {
  const videoRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);

  const PausePlayer = async () => {
    const videoElement = videoRef?.current;
    await videoElement?.pause();
    setIsPaused(true);
  };
  const PlayPlayer = async () => {
    const videoElement = videoRef?.current;
    await videoElement?.play();
    setIsPaused(false);
  };
  const PausePlay = () => {
    const videoElement = videoRef?.current;
    if (videoElement?.paused) {
      PlayPlayer();
    } else {
      PausePlayer();
    }
  };
  return (
    <div
      onClick={PausePlay}
      style={{
        width: width || "100%",
        height: height || "100%",
      }}
      className="video-wrapper"
    >
      {showControl ? (
        <div className=" playControl flex-column-center">
          {isPaused ? <FaRegPlayCircle /> : <FaCirclePause />}
        </div>
      ) : null}
      <video
        ref={videoRef}
        id={id || "vd"}
        width={width || "100%"}
        height={height || "100%"}
        autoPlay={autoPlay || false}
        loop={loop || false}
        controls={controls || false}
        muted={muted || false}
        poster={poster || AssetsImg.VideoBanner}
      >
        <source src={src} type="video/mp4" />
        <p>
          Your browser doesn't support HTML video. Here is a
          <a href={src}>link to the video</a> instead.
        </p>
      </video>
    </div>
  );
};

export default CustomVideo;
