import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
// import ar from "./locales/ar.json";
// import he from "./locales/he.json";
i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      // lng: "en",
      fallbackLng: "ar",
      detection: {
        order: ["path", "localStorage", "htmlTag", "cookie"],
        caches: ["localStorage", "cookie"], // cache user language on
      },
      // resources: {
      //   ar: {
      //     common: ar,
      //   },
      //   he: {
      //     common: he,
      //   },
      // },
    },
    (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      t("key"); // -> same as i18next.t
    }
  );

export default i18n;
