import { useEffect, useState } from "react";
const DeviceType = {
  LG_DESKTOP: "lgDesktop",
  DESKTOP: "desktop",
  TABLET: "tablet",
  MOBILE: "mobile",
  XS_MOBILE: "xsMobile",
};
const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState("");
  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      if (innerWidth > 1200) {
        setDeviceType(DeviceType.LG_DESKTOP);
      } else if (innerWidth <= 1200 && innerWidth >= 992) {
        setDeviceType(DeviceType.DESKTOP);
      } else if (innerWidth <= 991 && innerWidth >= 768) {
        setDeviceType(DeviceType.TABLET);
      } else if (innerWidth <= 577 && innerWidth > 300) {
        setDeviceType(DeviceType.MOBILE);
      } else if (innerWidth <= 300)  {
        setDeviceType(DeviceType.XS_MOBILE);
      }
    };
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    // Initial device type check
    handleResize();
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return deviceType;
};

export { DeviceType, useDeviceType };
