import React from "react";
import { useTranslation } from "react-i18next";
import Section from "../../components/section/section";
import AssetsImg from "../../assets/AssetsImg";

import "./services.css";

const Services = () => {
  const { t } = useTranslation();
  const services = [
    {
      id: 0,
      title: t("service_title1"),
      details: t("service_title_details1"),
      img: AssetsImg.Service1,
    },
    {
      id: 1,
      title: t("service_title2"),
      details: t("service_title_details2"),
      img: AssetsImg.Service3,
    },
    {
      id: 2,
      title: t("service_title3"),
      details: t("service_title_details3"),
      img: AssetsImg.Service5,
    },
    {
      id: 3,
      title: t("service_title4"),
      details: t("service_title_details4"),
      img: AssetsImg.Service4,
    },
    {
      id: 4,
      title: t("service_title5"),
      details: t("service_title_details5"),
      img: AssetsImg.Service2,
    },
    {
      id: 5,
      title: t("service_title6"),
      details: t("service_title_details6"),
      img: AssetsImg.Service6,
    },
    {
      id: 9,
      title: t("service_title10"),
      details: t("service_title_details10"),
      img: AssetsImg.Service10,
    },
    {
      id: 6,
      title: t("service_title7"),
      details: t("service_title_details7"),
      img: AssetsImg.Service7,
    },
    {
      id: 8,
      title: t("service_title9"),
      details: t("service_title_details9"),
      img: AssetsImg.Service9,
    },
    {
      id: 10,
      title: t("service_title11"),
      details: t("service_title_details11"),
      img: AssetsImg.Service11,
    },
    {
      id: 11,
      title: t("service_title12"),
      details: t("service_title_details12"),
      img: AssetsImg.Service12,
    },
    {
      id: 7,
      title: t("service_title8"),
      details: t("service_title_details8"),
      img: AssetsImg.Service8,
    },
    {
      id: 12,
      title: t("service_title13"),
      details: t("service_title_details13"),
      img: AssetsImg.Service13,
    },
    {
      id: 13,
      title: t("service_title14"),
      details: t("service_title_details14"),
      img: AssetsImg.Service14,
    },
  ];
  return (
    <Section id={"services"} title={t("section3")} text={t("gallery_sub")}>
      <div className="services_wrapper">
        {services.map((el) => (
          <div key={el.id} className="service_container flex-column-center">
            <div className="box vintage">
              <img
                src={el.img}
                alt={`serviceImg-${el.id}`}
                className="service_img"
              />
              <h2>{el.title}</h2>
              <p>{el.details}</p>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default Services;
