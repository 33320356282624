import React from "react";
import { useTranslation } from "react-i18next";
import Slider2 from "../slider2/slider2";
import Section from "../../components/section/section";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Section
      fadeEnabled={false}
      id={"about"}
      title={t("welcome_text")}
      button={{ link: "/About", title: t("read_more") }}
      text={t("about_us_sub")}
    >
      <Slider2 />
    </Section>
  );
};

export default AboutUs;
