import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "../../components/section/section";
import "./gallery.css";

import GalleryScroll from "./GalleryScroll";
import ImageModal from "./ImageModal";
import VideoModal from "./VideoModal";

const GalleryGrid = () => {
  const { t } = useTranslation();
  const [ImageViewerVisible, setImageViewerVisible] = useState({});
  const [VideoViewerVisible, setVideoViewerVisible] = useState({});
  return (
    <>
      <Section
        id={"gallery"}
        title={t("gallery")}
        button={{ link: "/Gallery", title: t("cta2") }}
        text={t("gallery_sub")}
        fadeEnabled={false}
      >
        <GalleryScroll
          videoHandler={(item) => setVideoViewerVisible(item)}
          galleryHandler={(item) => setImageViewerVisible(item)}
          isScroll={true}
        />
      </Section>
      <ImageModal
        visible={ImageViewerVisible.isVisible}
        images={ImageViewerVisible.images}
        selectedImage={ImageViewerVisible.selectedImage}
        onClose={() =>
          setImageViewerVisible({ ...ImageViewerVisible, isVisible: false })
        }
      />

      <VideoModal
        visible={VideoViewerVisible.isVisible}
        selectedVideo={VideoViewerVisible.selectedVideo}
        onClose={() =>
          setVideoViewerVisible({ ...VideoViewerVisible, isVisible: false })
        }
      />
    </>
  );
};

export default GalleryGrid;
