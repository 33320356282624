import React from "react";
import { useTranslation } from "react-i18next";
import "./notFound.css";
import Section from "../../components/section/section";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import AssetsImg from "../../assets/AssetsImg";

const NotFound = () => {
  const { t } = useTranslation();
  const crumbs = [
    { name: "section1", path: "/", key: 0 },
    { name: "PageNotFound", path: "notFound", key: 1 },
  ];
  return (
    <div className="index_wrapper page">
      <Breadcrumbs crumbs={crumbs} />
      <Section id="notfound" title={t("PageNotFound")}>
        <img src={AssetsImg.AboutCover} alt="logo" className="footer__logo" />
      </Section>
    </div>
  );
};

export default NotFound;
