import React from "react";
import Section from "../../components/section/section";
import { useTranslation } from "react-i18next";
import AssetsImg from "../../assets/AssetsImg";
import { DeviceType, useDeviceType } from "../../hooks/DeviceType";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./testimonials.css";
import { Pagination, Autoplay } from "swiper/modules";
const Testimonials = () => {
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const isMobile =
    deviceType === DeviceType.MOBILE || deviceType === DeviceType.XS_MOBILE;
  const isTablet = deviceType === DeviceType.TABLET;
  const SlidesPerView = isMobile ? 1 : isTablet ? 2 : 3;
  const testimonialsList = [
    {
      id: 1,
      clientAvatar: AssetsImg.Avatar,
      clientName: "Client 1",
      review: t("testimonial1"),
    },
    {
      id: 2,
      clientAvatar: AssetsImg.Avatar,
      clientName: "Client 2",
      review: t("testimonial2"),
    },
    {
      id: 3,
      clientAvatar: AssetsImg.Avatar,
      clientName: "Client 3",
      review: t("testimonial3"),
    },
    {
      id: 4,
      clientAvatar: AssetsImg.Avatar,
      clientName: "Client 4",
      review: t("testimonial3"),
    },
    {
      id: 5,
      clientAvatar: AssetsImg.Avatar,
      clientName: "Client 5",
      review: t("testimonial5"),
    },
    {
      id: 6,
      clientAvatar: AssetsImg.Avatar,
      clientName: "Client 6",
      review: t("testimonial6"),
    },
    {
      id: 7,
      clientAvatar: AssetsImg.Avatar,
      clientName: "Client 7",
      review: t("testimonial7"),
    },
  ];
  return (
    <Section id={"testimonials"} title={t("testimonials")}>
      <div className="flex-row-center rating">
        <img src={AssetsImg.Rating} alt={"rating"} />
        <h5>5.0/5.0</h5>
      </div>
      <Swiper
        spaceBetween={30}
        slidesPerView={SlidesPerView}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        modules={[Pagination, Autoplay]}
        className="testimonials__container"
      >
        {testimonialsList.map(({ id, clientAvatar, clientName, review }) => {
          return (
            <SwiperSlide key={id}>
              <div className="flex-column-center testimonial">
                <div className="client__avatar">
                  <img src={clientAvatar} alt={clientName} />
                </div>
                {/* <h5 className="client__name">{clientName}</h5> */}
                <small className="client__review">{review}</small>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Section>
  );
};

export default Testimonials;
