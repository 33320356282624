import React from "react";
import "./section.css";
import { BsArrowLeft } from "react-icons/bs";
const SectionTitle = ({ text, button }) => {
  return text || button ? (
    <div className={`section_sub_row ${button ? '' : 'section_sub_row_noButton'}`}>
      {text ? <span>{text}</span> : null}
      {button ? (
        <div className="section_button_wrapper">
          <a href={button.link} className="btn">
            {button.title}
            <BsArrowLeft className="btn-icon" />
          </a>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default SectionTitle;
