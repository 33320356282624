import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "./slider2.css";
// import required modules
import { EffectFade, Autoplay, Navigation } from "swiper/modules";
import AssetsImg from "../../assets/AssetsImg";
import { useTranslation } from "react-i18next";

const Slider2 = ({ isPanoramic }) => {
  const { t } = useTranslation();
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  const sliders = [
    {
      id: 0,
      image: AssetsImg.Slider1,
      header: t("slider1"),
    },
    {
      id: 1,
      image: AssetsImg.Slider2,
      header: t("slider2"),
    },
    {
      id: 2,
      image: AssetsImg.Slider3,
      header: t("slider3"),
    },
    {
      id: 3,
      image: AssetsImg.Slider4,
      header: t("slider4"),
    },
    {
      id: 4,
      image: AssetsImg.Slider5,
      header: t("slider5"),
    },
  ];
  return (
    <Swiper
      spaceBetween={30}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      effect={"fade"}
      loop={true}
      navigation={true}
      modules={[EffectFade, Autoplay, Navigation]}
      onAutoplayTimeLeft={onAutoplayTimeLeft}
      className="aboutSlider"
    >
      {sliders.map((el) => (
        <SwiperSlide key={el.id}>
          <div className="sliderWrapper">
            <img
              src={el.image}
              alt={el.header}
              data-img-preview={isPanoramic ? "panoramic" : "cover"}
            />
            <div className="overlay absolute-element flex-column-center">
              <h1 className="title">{el.header}</h1>
            </div>
          </div>
        </SwiperSlide>
      ))}
      <div className="autoplay-progress" slot="container-end">
        <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
        <span ref={progressContent}></span>
      </div>
    </Swiper>
  );
};
export default Slider2;
