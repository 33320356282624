import React from "react";
import "./header.css";
import CTA from "./CTA";
import Caption from "./Caption";
import IPadFrame from "../ipad/IPadFrame";
const Header = () => {
  return (
    <header id="main">
      <div className="container header__container flex-column-center">
        <h1 className="custom_header bottom-rotate">
          {"Give a "}
          <span className="special_span1">{" surprise "}</span>
        </h1>
        <h1 className="custom_header top-rotate">
          {"to your "}
          <strong className="strong_underline" style={{ color: "#007eff" }}>
            {"loved"}
          </strong>
          {" one on their "}
          <strong className="strong_underline" style={{ color: "#ffaf00" }}>
            {"birthday"}
          </strong>
        </h1>
        <IPadFrame />
        <CTA />
        <Caption />
      </div>
    </header>
  );
};

export default Header;
