import React, { useState } from "react";
import Section from "../components/section/section";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import Slider2 from "../partials/slider2/slider2";
import ImageModal from "../partials/gallery/ImageModal";
import VideoModal from "../partials/gallery/VideoModal";
import AssetsVideo from "../assets/AssetsVideo";
import AssetsImg from "../assets/AssetsImg";
import Video from "../components/video/Video";

const FilterBy = {
  ALL: "ALL",
  WORKSHOP: "WORKSHOP",
  BIRTHDAY: "Birthday",
};
const GalleryPage = () => {
  const { t } = useTranslation();
  // const [filterBy, setFilterBy] = useState(FilterBy.ALL);
  const [ImageViewerVisible, setImageViewerVisible] = useState({});
  const [VideoViewerVisible, setVideoViewerVisible] = useState({});
  const crumbs = [
    { name: "section1", path: "/", key: 0 },
    { name: "cta2", path: "Gallery", key: 1 },
  ];
  const gallery = [
    {
      id: "birthday1",
      title: "birthday",
      images: [
        {
          type: FilterBy.BIRTHDAY,
          id: "vIntro",
          img: AssetsVideo.FuntasticIntro,
          isVideo: true,
        },
        { type: FilterBy.BIRTHDAY, id: "6", img: AssetsImg.GalleryImg6 },
        { type: FilterBy.BIRTHDAY, id: "7", img: AssetsImg.GalleryImg7 },
        { type: FilterBy.BIRTHDAY, id: "8", img: AssetsImg.GalleryImg8 },
        { type: FilterBy.BIRTHDAY, id: "9", img: AssetsImg.GalleryImg9 },
        { type: FilterBy.BIRTHDAY, id: "10", img: AssetsImg.GalleryImg10 },
        { type: FilterBy.BIRTHDAY, id: "11", img: AssetsImg.GalleryImg11 },
        {
          type: FilterBy.BIRTHDAY,
          id: "v4",
          img: AssetsVideo.FuntasticBD4,
          isVideo: true,
        },
        {
          type: FilterBy.BIRTHDAY,
          id: "v5",
          img: AssetsVideo.FuntasticBD5,
          isVideo: true,
        },
        {
          type: FilterBy.BIRTHDAY,
          id: "v6",
          img: AssetsVideo.FuntasticBD6,
          isVideo: true,
        },
        { type: FilterBy.BIRTHDAY, id: "12", img: AssetsImg.GalleryImg12 },
        { type: FilterBy.BIRTHDAY, id: "14", img: AssetsImg.GalleryImg14 },
        { type: FilterBy.BIRTHDAY, id: "15", img: AssetsImg.GalleryImg15 },
        { type: FilterBy.BIRTHDAY, id: "16", img: AssetsImg.GalleryImg16 },
        {
          type: FilterBy.BIRTHDAY,
          id: "v1",
          img: AssetsVideo.FuntasticBD1,
          isVideo: true,
        },
        { type: FilterBy.BIRTHDAY, id: "13", img: AssetsImg.GalleryImg13 },
        {
          type: FilterBy.BIRTHDAY,
          id: "v2",
          img: AssetsVideo.FuntasticBD2,
          isVideo: true,
        },
        {
          type: FilterBy.BIRTHDAY,
          id: "v3",
          img: AssetsVideo.FuntasticBD3,
          isVideo: true,
        },
        {
          type: FilterBy.BIRTHDAY,
          id: "v7",
          img: AssetsVideo.FuntasticBD7,
          isVideo: true,
        },
        { type: FilterBy.BIRTHDAY, id: "17", img: AssetsImg.GalleryImg17 },
        {
          type: FilterBy.BIRTHDAY,
          id: "v10",
          img: AssetsVideo.FuntasticBD10,
          isVideo: true,
        },
        { type: FilterBy.BIRTHDAY, id: "18", img: AssetsImg.GalleryImg18 },
        {
          type: FilterBy.BIRTHDAY,
          id: "v9",
          img: AssetsVideo.FuntasticBD9,
          isVideo: true,
        },
        { type: FilterBy.BIRTHDAY, id: "19", img: AssetsImg.GalleryImg19 },
        { type: FilterBy.BIRTHDAY, id: "20", img: AssetsImg.GalleryImg20 },
        { type: FilterBy.BIRTHDAY, id: "21", img: AssetsImg.GalleryImg21 },
        { type: FilterBy.BIRTHDAY, id: "22", img: AssetsImg.GalleryImg22 },
        { type: FilterBy.BIRTHDAY, id: "23", img: AssetsImg.GalleryImg23 },
        { type: FilterBy.BIRTHDAY, id: "24", img: AssetsImg.GalleryImg24 },
        {
          type: FilterBy.BIRTHDAY,
          id: "v8",
          img: AssetsVideo.FuntasticBD8,
          isVideo: true,
        },
        { type: FilterBy.BIRTHDAY, id: "25", img: AssetsImg.GalleryImg25 },
        { type: FilterBy.BIRTHDAY, id: "26", img: AssetsImg.GalleryImg26 },

        { type: FilterBy.BIRTHDAY, id: "27", img: AssetsImg.GalleryImg27 },
      ],
    },
    {
      id: "workshops2",
      title: "workshops",
      images: [
        { type: FilterBy.WORKSHOP, id: "1", img: AssetsImg.GalleryImg1 },
        { type: FilterBy.WORKSHOP, id: "2", img: AssetsImg.GalleryImg2 },
        { type: FilterBy.WORKSHOP, id: "3", img: AssetsImg.GalleryImg3 },
        {
          type: FilterBy.WORKSHOP,
          id: "vWorkshop3",
          img: AssetsVideo.FuntasticWorkshop3,
          isVideo: true,
        },
        { type: FilterBy.WORKSHOP, id: "5", img: AssetsImg.GalleryImg5 },
        { type: FilterBy.WORKSHOP, id: "28", img: AssetsImg.GalleryImg28 },
        { type: FilterBy.WORKSHOP, id: "4", img: AssetsImg.GalleryImg4 },
        { type: FilterBy.WORKSHOP, id: "30", img: AssetsImg.GalleryImg30 },
        {
          type: FilterBy.WORKSHOP,
          id: "vWorkshop1",
          img: AssetsVideo.FuntasticWorkshop1,
          isVideo: true,
        },
        { type: FilterBy.WORKSHOP, id: "31", img: AssetsImg.GalleryImg31 },
        { type: FilterBy.WORKSHOP, id: "33", img: AssetsImg.GalleryImg33 },
        { type: FilterBy.WORKSHOP, id: "32", img: AssetsImg.GalleryImg32 },
        { type: FilterBy.WORKSHOP, id: "29", img: AssetsImg.GalleryImg29 },
        {
          type: FilterBy.WORKSHOP,
          id: "vWorkshop2",
          img: AssetsVideo.FuntasticWorkshop2,
          isVideo: true,
        },
      ],
    },
  ];
  const openGallery = (id, galleryItem) => {
    setImageViewerVisible({
      isVisible: true,
      selectedImage: galleryItem.id,
      images: gallery
        ?.find((el) => el.id === id)
        ?.images.filter((el) => !el.isVideo),
    });
  };
  const openVideo = (video) => {
    setVideoViewerVisible({
      isVisible: true,
      selectedVideo: video,
    });
  };
  return (
    <>
      <div className="index_wrapper page">
        <Breadcrumbs crumbs={crumbs} />
        <Section
          id={"gallery"}
          title={t("gallery")}
          text={t("gallery_sub")}
          fadeEnabled={false}
        >
          <div className="flex-column-center gallery_grid_filters">
            {gallery.map((el) => (
              <div key={el.id} style={{ marginBottom: "3rem" }}>
                <h2 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  {t(el.title)}
                </h2>
                <div className="scroll-wrapper">
                  <div
                    className="scroll"
                    style={{ "--time": "30s" }}
                    data-scrolling={"false"}
                  >
                    <div className="gallery_wrapper-scroll">
                      {el.images.map((img) => (
                        <div
                          key={img.id}
                          className="column"
                          onClick={() =>
                            img.isVideo
                              ? openVideo(img.img)
                              : openGallery(el.id, img)
                          }
                        >
                          {img.isVideo ? (
                            <Video
                              width="100%"
                              height="100%"
                              autoPlay
                              src={img.img}
                              loop
                              muted
                              id="size"
                              showControl={false}
                            />
                          ) : (
                            <img
                              id="size"
                              src={img.img}
                              alt={`galleryImg-${img.id}`}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Slider2 isPanoramic />
        </Section>
      </div>
      <ImageModal
        visible={ImageViewerVisible.isVisible}
        images={ImageViewerVisible.images}
        selectedImage={ImageViewerVisible.selectedImage}
        onClose={() =>
          setImageViewerVisible({ ...ImageViewerVisible, isVisible: false })
        }
      />
      <VideoModal
        visible={VideoViewerVisible.isVisible}
        selectedVideo={VideoViewerVisible.selectedVideo}
        onClose={() =>
          setVideoViewerVisible({ ...VideoViewerVisible, isVisible: false })
        }
      />
    </>
  );
};

export default GalleryPage;
