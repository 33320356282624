import React, { useEffect, useRef, useState } from "react";
import "./IPadFrame.css";
import AssetsImg from "../../assets/AssetsImg";
import AssetsVideo from "../../assets/AssetsVideo";
import { FaRegPlayCircle } from "react-icons/fa";
import { AiOutlineExpand } from "react-icons/ai";
import { FaCirclePause } from "react-icons/fa6";
const IPadFrame = () => {
  const videoRef = useRef(null);
  //   const videoElement = document.getElementById("intro_video");
  const [isPaused, setIsPaused] = useState(true);
  const PausePlayer = async () => {
    // console.log("playing");
    const videoElement = videoRef?.current;
    await videoElement?.pause();
    setIsPaused(true);
  };
  const PlayPlayer = async () => {
    const videoElement = videoRef?.current;
    await videoElement?.play();
    setIsPaused(false);
    // console.log("paused");
  };
  const PausePlay = () => {
    const videoElement = videoRef?.current;
    // console.log("PausePlay");
    if (videoElement?.paused) {
      PlayPlayer();
    } else {
      PausePlayer();
    }
  };
  const FullScreen = () => {
    const videoElement = videoRef?.current;
    videoElement?.webkitEnterFullscreen();
  };

  useEffect(() => {
    // if (!videoElement?.playing) {
    //
    // }
    try {
    } catch (e) {
      console.log(e);
    }
    return () => {
      PausePlayer();
    };
  }, []);
  return (
    <div id="ipad-wrapper">
      <div id="ipad">
        <div id="ipad-border">
          <div id="ipad-bezel">
            <div id="screen" onClick={PausePlay}>
              <div className=" control play-control flex-column-center">
                {isPaused ? <FaRegPlayCircle /> : <FaCirclePause />}
              </div>
              <div
                className="fullScreen-control control flex-column-center"
                onClick={FullScreen}
              >
                <AiOutlineExpand />
              </div>
              {/* <Video
                ref={videoRef}
                id="intro_video"
                width="100%"
                height="100%"
                autoPlay
                // controls
                src={AssetsVideo.FuntasticIntro}
                loop
                // muted
                poster={AssetsImg.GalleryImg17}
              /> */}
              <video
                ref={videoRef}
                id="intro_video"
                width="100%"
                height="100%"
                autoPlay
                // controls
                loop
                // muted
                poster={AssetsImg.VideoBanner}
              >
                <source src={AssetsVideo.FuntasticIntro} type="video/mp4" />
                <p>
                  Your browser doesn't support HTML video. Here is a
                  <a href={AssetsVideo.FuntasticIntro}>link to the video</a>{" "}
                  instead.
                </p>
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPadFrame;
