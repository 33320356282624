import React from "react";
import AssetsImg from "../../assets/AssetsImg";
import "./gallery.css";
import AssetsVideo from "../../assets/AssetsVideo";

import Video from "../../components/video/Video";

const AssetType = {
  WORKSHOP: "WORKSHOP",
  BIRTHDAY: "Birthday",
};
const GalleryScroll = ({ videoHandler, galleryHandler, isScroll = false }) => {
  const gallery = [
    { type: AssetType.WORKSHOP, id: "1", img: AssetsImg.GalleryImg1 },
    { type: AssetType.WORKSHOP, id: "2", img: AssetsImg.GalleryImg2 },
    { type: AssetType.WORKSHOP, id: "3", img: AssetsImg.GalleryImg3 },
    { type: AssetType.WORKSHOP, id: "5", img: AssetsImg.GalleryImg5 },
    {
      type: AssetType.BIRTHDAY,
      id: "vIntro",
      img: AssetsVideo.FuntasticIntro,
      isVideo: true,
    },
    { type: AssetType.BIRTHDAY, id: "6", img: AssetsImg.GalleryImg6 },
    { type: AssetType.BIRTHDAY, id: "7", img: AssetsImg.GalleryImg7 },
    { type: AssetType.BIRTHDAY, id: "8", img: AssetsImg.GalleryImg8 },
    { type: AssetType.BIRTHDAY, id: "9", img: AssetsImg.GalleryImg9 },
    { type: AssetType.BIRTHDAY, id: "10", img: AssetsImg.GalleryImg10 },
    { type: AssetType.BIRTHDAY, id: "11", img: AssetsImg.GalleryImg11 },
    {
      type: AssetType.BIRTHDAY,
      id: "v4",
      img: AssetsVideo.FuntasticBD4,
      isVideo: true,
    },
    {
      type: AssetType.BIRTHDAY,
      id: "v5",
      img: AssetsVideo.FuntasticBD5,
      isVideo: true,
    },
    { type: AssetType.WORKSHOP, id: "28", img: AssetsImg.GalleryImg28 },
    {
      type: AssetType.BIRTHDAY,
      id: "v6",
      img: AssetsVideo.FuntasticBD6,
      isVideo: true,
    },
    { type: AssetType.WORKSHOP, id: "4", img: AssetsImg.GalleryImg4 },
    { type: AssetType.BIRTHDAY, id: "12", img: AssetsImg.GalleryImg12 },
    { type: AssetType.WORKSHOP, id: "30", img: AssetsImg.GalleryImg30 },

    {
      type: AssetType.WORKSHOP,
      id: "vWorkshop1",
      img: AssetsVideo.FuntasticWorkshop1,
      isVideo: true,
    },
    { type: AssetType.BIRTHDAY, id: "14", img: AssetsImg.GalleryImg14 },
    { type: AssetType.WORKSHOP, id: "31", img: AssetsImg.GalleryImg31 },
    { type: AssetType.WORKSHOP, id: "33", img: AssetsImg.GalleryImg33 },
    { type: AssetType.BIRTHDAY, id: "15", img: AssetsImg.GalleryImg15 },
    { type: AssetType.BIRTHDAY, id: "16", img: AssetsImg.GalleryImg16 },
    {
      type: AssetType.BIRTHDAY,
      id: "v1",
      img: AssetsVideo.FuntasticBD1,
      isVideo: true,
    },
    { type: AssetType.BIRTHDAY, id: "13", img: AssetsImg.GalleryImg13 },
    {
      type: AssetType.BIRTHDAY,
      id: "v2",
      img: AssetsVideo.FuntasticBD2,
      isVideo: true,
    },
    {
      type: AssetType.BIRTHDAY,
      id: "v3",
      img: AssetsVideo.FuntasticBD3,
      isVideo: true,
    },
    {
      type: AssetType.BIRTHDAY,
      id: "v7",
      img: AssetsVideo.FuntasticBD7,
      isVideo: true,
    },
    { type: AssetType.BIRTHDAY, id: "17", img: AssetsImg.GalleryImg17 },
    {
      type: AssetType.BIRTHDAY,
      id: "v10",
      img: AssetsVideo.FuntasticBD10,
      isVideo: true,
    },
    { type: AssetType.BIRTHDAY, id: "18", img: AssetsImg.GalleryImg18 },
    {
      type: AssetType.BIRTHDAY,
      id: "v9",
      img: AssetsVideo.FuntasticBD9,
      isVideo: true,
    },
    { type: AssetType.BIRTHDAY, id: "19", img: AssetsImg.GalleryImg19 },
    { type: AssetType.BIRTHDAY, id: "20", img: AssetsImg.GalleryImg20 },
    { type: AssetType.WORKSHOP, id: "32", img: AssetsImg.GalleryImg32 },
    { type: AssetType.BIRTHDAY, id: "21", img: AssetsImg.GalleryImg21 },
    { type: AssetType.BIRTHDAY, id: "22", img: AssetsImg.GalleryImg22 },
    { type: AssetType.BIRTHDAY, id: "23", img: AssetsImg.GalleryImg23 },
    { type: AssetType.BIRTHDAY, id: "24", img: AssetsImg.GalleryImg24 },
    {
      type: AssetType.BIRTHDAY,
      id: "v8",
      img: AssetsVideo.FuntasticBD8,
      isVideo: true,
    },
    { type: AssetType.WORKSHOP, id: "29", img: AssetsImg.GalleryImg29 },
    { type: AssetType.BIRTHDAY, id: "25", img: AssetsImg.GalleryImg25 },
    { type: AssetType.BIRTHDAY, id: "26", img: AssetsImg.GalleryImg26 },
    {
      type: AssetType.WORKSHOP,
      id: "vWorkshop2",
      img: AssetsVideo.FuntasticWorkshop2,
      isVideo: true,
    },
    { type: AssetType.BIRTHDAY, id: "27", img: AssetsImg.GalleryImg27 },
    {
      type: AssetType.WORKSHOP,
      id: "vWorkshop3",
      img: AssetsVideo.FuntasticWorkshop3,
      isVideo: true,
    },
  ];
  const openGallery = (galleryItem) => {
    galleryHandler &&
      galleryHandler({
        isVisible: true,
        selectedImage: galleryItem.id,
        images: gallery.filter((el) => !el.isVideo),
      });
  };
  const openVideo = (video) => {
    videoHandler &&
      videoHandler({
        isVisible: true,
        selectedVideo: video,
      });
  };
  // https://codepen.io/jensimmons/pen/QWjqbJj?editors=1100
  // https://codepen.io/CyFox/pen/MWWEwjg
  return (
    <div className="scroll-wrapper">
      <div
        className="scroll"
        style={{ "--time": "30s" }}
        data-scrolling={isScroll ? "true" : "false"}
      >
        <div className="gallery_wrapper-scroll">
          {gallery.map((el) => (
            <div
              key={el.id}
              className="column"
              onClick={() => (el.isVideo ? openVideo(el.img) : openGallery(el))}
            >
              {el.isVideo ? (
                <Video
                  width="100%"
                  height="100%"
                  autoPlay
                  src={el.img}
                  loop
                  muted
                  id="size"
                  showControl={false}
                />
              ) : (
                <img id="size" src={el.img} alt={`galleryImg-${el.id}`} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryScroll;
