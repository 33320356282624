import React, { Suspense, useEffect, useState } from "react";
import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import i18n from "./i18n";
import NotFound from "./pages/notFound/NotFound";
import Layout from "./pages/Layout";
import Loader from "./components/loader/Loader";
import GalleryPage from "./pages/Gallery";
import About from "./pages/about/About";
const App = () => {
  const [visible, setVisible] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const saved = localStorage.getItem("lang");
    return saved !== null ? saved : "he";
  });
  const replaceLanguageClass = (newLanguage) => {
    const prevLang = newLanguage === "he" ? "ar" : "he";
    const prevLanguageClass = `lang-${prevLang}`;
    const languageClass = `lang-${newLanguage}`;
    document.body.classList.remove(prevLanguageClass);
    document.body.classList.add(languageClass);
  };
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  useEffect(() => {
    const AppMount = async () => {
      const languages = [
        { name: "العربية", code: "ar", dir: "rtl" },
        { name: "עברית", code: "he", dir: "rtl" },
        // { name: "English", code: "en", dir: "lrt" },
      ];
      const currentLangObj = languages.find(
        (lang) => lang.code === currentLanguage
      );
      localStorage.setItem("lang", currentLangObj?.code);
      document.body.dir = currentLangObj?.dir ?? "rtl";
      replaceLanguageClass(currentLangObj?.code);

      await i18n.changeLanguage(currentLangObj?.code);
    };
    AppMount();
  }, [currentLanguage]);
  const handleSiteLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    setCurrentLanguage(lang);
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  const CustomLayout = ({ isWithNav }) => (
    <Layout
      visible={visible}
      currentLanguage={currentLanguage}
      scrollToTop={scrollToTop}
      handleSiteLanguage={handleSiteLanguage}
      isWithNav={isWithNav}
    />
  );
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<CustomLayout isWithNav={true} />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="/" element={<CustomLayout isWithNav={false} />}>
            <Route path="Gallery" element={<GalleryPage />} />
            <Route path="About" element={<About />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
