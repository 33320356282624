import { useRef } from "react";

const useScrollToTop = () => {
  const elementRef = useRef(null);
  // const [activeNav, setActiveNav] = useState("");
  const scrollToTop = (elementId) => {
    const targetElement = document.getElementById(elementId);
    // setActiveNav(elementId);
    if (targetElement) {
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      const targetPosition = elementPosition + offset - 190; // Adjust the offset as needed
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth", // Use 'auto' for instant scroll without smooth animation
      });
    }
  };

  return { elementRef, scrollToTop };
};

export default useScrollToTop;
