import React from "react";
import Header from "../partials/header/Header";
import Testimonials from "../partials/testimonials/Testimonials";
// import Slider from "../partials/slider/slider";
import AboutUs from "../partials/aboutUs/aboutUs";
// import Gallery from "../partials/gallery/gallery";
import Services from "../partials/services/services";
import Packages from "../partials/packages/packages";
import PageSection from "../partials/pageSection";
import Contact from "../partials/contact/Contact";
import Instagram from "../partials/instagram/instagram";
import GalleryGrid from "../partials/gallery/GalleryGrid";
import GeneralServices from "../partials/services/GeneralServices";
// import Contact from "../components/contact/Contact";

const Home = () => {
  return (
    <div className="index_wrapper">
      <Header />
      <AboutUs />
      <GeneralServices />
      <PageSection />
      <GalleryGrid />
      <Packages />
      {/* <Gallery /> */}
      {/* <Slider /> */}
      <Contact />
      <Services />
      <Instagram />
      <Testimonials />
    </div>
  );
};

export default Home;
