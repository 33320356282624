import React from "react";
import BaseModal from "../../modals/BaseModal";

import useEscapeKey from "../../hooks/useEscapeKey";
import AssetsImg from "../../assets/AssetsImg";
import CustomVideo from "../../components/video/Video";

const VideoModal = ({ selectedVideo, onClose, visible }) => {
  useEscapeKey(onClose);
  // const ref = useRef(null);
  // useOutsideClick(onClose, ref);
  return !visible ? null : (
    <BaseModal visible={visible} onClose={onClose} withoutBg>
      <CustomVideo
        width="100%"
        height="100%"
        autoPlay
        src={selectedVideo}
        id="size"
        poster={AssetsImg.VideoBanner}
      />
    </BaseModal>
  );
};

export default VideoModal;
