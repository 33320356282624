import React from "react";
import Section from "../components/section/section";

const PageSection = () => {
  return (
    <Section id="pageSection" removePadding={true}>
      <div className="pageSection flex-column-center header__container">
        <h1>
          <strong style={{ color: "#FF009A" }}>{"Turning"}</strong>
          {" your dreams into "}
          <strong style={{ color: "#007eff" }}>{"unforgettable"}</strong>
          {" experiences"}
        </h1>
      </div>
    </Section>
  );
};

export default PageSection;
