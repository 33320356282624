import BrandLogo from "../assets/images/brandLogo.webp";
import celebrateIco1 from "../assets/images/celebrateIco1.png";
import celebrateIco2 from "../assets/images/celebrateIco2.png";
import Slider1 from "../assets/images/slider/s1.webp";
import Slider2 from "../assets/images/slider/s2.webp";
import Slider3 from "../assets/images/slider/s3.webp";
import Slider4 from "../assets/images/slider/s4.webp";
import Slider5 from "../assets/images/slider/s5.webp";
import CaptionImg from "../assets/images/caption_img.png";
import SliderOverlay from "../assets/images/slider/slider_overlay.png";
import Service1 from "../assets/images/services/service1.webp";
import Service2 from "../assets/images/services/service2.webp";
import Service3 from "../assets/images/services/service3.webp";
import Service4 from "../assets/images/services/service4.webp";
import Service5 from "../assets/images/services/service5.webp";
import Service6 from "../assets/images/services/service6.webp";
import Service7 from "../assets/images/services/service7.webp";
import Service8 from "../assets/images/services/service8.webp";
import Service9 from "../assets/images/services/service9.webp";
import Service10 from "../assets/images/services/service10.webp";
import Service11 from "../assets/images/services/service11.webp";
import Service12 from "../assets/images/services/service12.webp";
import Service13 from "../assets/images/services/service13.webp";
import Service14 from "../assets/images/services/service14.webp";
import ServiceWorkshop from "../assets/images/services/service_workshop.png";
import ServiceBD from "../assets/images/services/service_bd.png";
import ServiceArt from "../assets/images/services/service_art.png";
import PartialSectionBG from "../assets/images/partial_section_bg.png";
import StickersPattern from "../assets/images/stickers_pattern.webp";
import Whatsapp from "../assets/images/whatsapp.png";
import Avatar from "../assets/images/avatar.png";
import Rating from "../assets/images/rating.png";
import Girl from "../assets/images/girl.webp";
import Smile from "../assets/images/smile.png";
import Balloon from "../assets/images/balloon.png";
import Pattern from "../assets/images/pattern1.png";
import AboutCover from "../assets/images/about_cover.png";
import InstagramPage from "../assets/images/instagram_page.png";
import InstagramGrid from "../assets/images/instagram_grid.webp";
import VideoBanner from "../assets/images/video_banner.webp";
import GalleryImg1 from "../assets/images/gallery/funtastic-place_gallery1.webp";
import GalleryImg2 from "../assets/images/gallery/funtastic-place_gallery2.webp";
import GalleryImg3 from "../assets/images/gallery/funtastic-place_gallery3.webp";
import GalleryImg4 from "../assets/images/gallery/funtastic-place_gallery4.webp";
import GalleryImg5 from "../assets/images/gallery/funtastic-place_gallery5.webp";
import GalleryImg6 from "../assets/images/gallery/funtastic-place_gallery6.webp";
import GalleryImg7 from "../assets/images/gallery/funtastic-place_gallery7.webp";
import GalleryImg8 from "../assets/images/gallery/funtastic-place_gallery8.webp";
import GalleryImg9 from "../assets/images/gallery/funtastic-place_gallery9.webp";
import GalleryImg10 from "../assets/images/gallery/funtastic-place_gallery10.webp";
import GalleryImg11 from "../assets/images/gallery/funtastic-place_gallery11.webp";
import GalleryImg12 from "../assets/images/gallery/funtastic-place_gallery12.webp";
import GalleryImg13 from "../assets/images/gallery/funtastic-place_gallery13.webp";
import GalleryImg14 from "../assets/images/gallery/funtastic-place_gallery14.webp";
import GalleryImg15 from "../assets/images/gallery/funtastic-place_gallery15.webp";
import GalleryImg16 from "../assets/images/gallery/funtastic-place_gallery16.webp";
import GalleryImg17 from "../assets/images/gallery/funtastic-place_gallery17.webp";
import GalleryImg18 from "../assets/images/gallery/funtastic-place_gallery18.webp";
import GalleryImg19 from "../assets/images/gallery/funtastic-place_gallery19.webp";
import GalleryImg20 from "../assets/images/gallery/funtastic-place_gallery20.webp";
import GalleryImg21 from "../assets/images/gallery/funtastic-place_gallery21.webp";
import GalleryImg22 from "../assets/images/gallery/funtastic-place_gallery22.webp";
import GalleryImg23 from "../assets/images/gallery/funtastic-place_gallery23.webp";
import GalleryImg24 from "../assets/images/gallery/funtastic-place_gallery24.webp";
import GalleryImg25 from "../assets/images/gallery/funtastic-place_gallery25.webp";
import GalleryImg26 from "../assets/images/gallery/funtastic-place_gallery26.webp";
import GalleryImg27 from "../assets/images/gallery/funtastic-place_gallery27.webp";
import GalleryImg28 from "../assets/images/gallery/funtastic-place-workshop1.webp";
import GalleryImg29 from "../assets/images/gallery/funtastic-place-workshop2.webp";
import GalleryImg30 from "../assets/images/gallery/funtastic-place-workshop3.webp";
import GalleryImg31 from "../assets/images/gallery/funtastic-place-workshop4.webp";
import GalleryImg32 from "../assets/images/gallery/funtastic-place-workshop5.webp";
import GalleryImg33 from "../assets/images/gallery/funtastic-place-workshop6.webp";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  VideoBanner,
  InstagramPage,
  InstagramGrid,
  StickersPattern,
  AboutCover,
  Pattern,
  Girl,
  Smile,
  Balloon,
  Rating,
  Avatar,
  Whatsapp,
  PartialSectionBG,
  BrandLogo,
  celebrateIco1,
  celebrateIco2,
  Slider1,
  Slider2,
  Slider3,
  Slider4,
  Slider5,
  CaptionImg,
  SliderOverlay,
  Service1,
  Service2,
  Service3,
  Service4,
  Service5,
  Service6,
  Service7,
  Service8,
  Service9,
  Service10,
  Service11,
  Service12,
  Service13,
  Service14,
  ServiceWorkshop,
  ServiceBD,
  ServiceArt,
  GalleryImg1,
  GalleryImg2,
  GalleryImg3,
  GalleryImg4,
  GalleryImg5,
  GalleryImg6,
  GalleryImg7,
  GalleryImg8,
  GalleryImg9,
  GalleryImg10,
  GalleryImg11,
  GalleryImg12,
  GalleryImg13,
  GalleryImg14,
  GalleryImg15,
  GalleryImg16,
  GalleryImg17,
  GalleryImg18,
  GalleryImg19,
  GalleryImg20,
  GalleryImg21,
  GalleryImg22,
  GalleryImg23,
  GalleryImg24,
  GalleryImg25,
  GalleryImg26,
  GalleryImg27,
  GalleryImg28,
  GalleryImg29,
  GalleryImg30,
  GalleryImg31,
  GalleryImg32,
  GalleryImg33,
};
